import {
  createTheme,
  CssBaseline,
  Grid,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { lazy, Suspense, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getDesignTheme } from "./Theme/DesignTheme";
import { Navigate, Route, Routes } from "react-router";
import { Provider } from "react-redux";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Store from "../Redux/Store";
import SuspensePage from "./Suspense/SuspensePage";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import "./App.css";
import { onMessageListener } from "../Imports/Firebase";

// components importing using lazy
const InvalidAccess = lazy(() => import("../Components/Common/InvalidAccess"));
const ComingSoon = lazy(() => import("../Components/Common/ComingSoon"));
const ErrorPage = lazy(() => import("../Components/Common/ErrorPage"));
const NotFound = lazy(() => import("../Components/Common/404"));
const ManageAuth = lazy(() => import("../Layouts/AuthSection"));
const HomeAccess = lazy(() => import("../Layouts/HomeAccess"));
const ManageUserSwitch = lazy(() => import("../Layouts/UserSwitchSection"));
const VisitorMain = lazy(() => import("../Layouts/VisitorMainRoutes"));
const RedirectionLayout = lazy(() => import("../Layouts/RedirectionScreen"));
const PublicForm = lazy(() =>
  import("../Components/PublicJobs/PublicFormRoute")
);
const ApplicantList = lazy(() =>
  import("../Components/PublicJobs/ApplicantListing/ApplicantRoute")
);

function App() {
  const theme = useMemo(
    () => responsiveFontSizes(createTheme(getDesignTheme("light"))),
    []
  );
  if (process.env.FIREBASE_STATUS === "true") onMessageListener();

  return (
    <Grid sx={{ m: 0, p: 0, width: "100%" }}>
      <Provider store={Store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {/* Pages Routes */}
            <BrowserRouter>
              <Suspense fallback={<SuspensePage />}>
                <CssBaseline />
                <Routes>
                  <Route path="auth/*" element={<ManageAuth />} />
                  <Route path="user/*" element={<ManageUserSwitch />} />
                  <Route path="redirect/*" element={<RedirectionLayout />} />
                  <Route path="home/*" element={<HomeAccess />} />
                  <Route
                    path="form/:schoolUid/:id/*"
                    element={<PublicForm />}
                  />
                  <Route path="/applicant/*" element={<ApplicantList />} />
                  <Route path="visitor/*" element={<VisitorMain />} />
                  <Route path="error" element={<ErrorPage />} />
                  <Route path="invalid" element={<InvalidAccess />} />
                  <Route path="soon" element={<ComingSoon />} />
                  <Route path="404" element={<NotFound main={true} />} />
                  <Route path="" element={<Navigate replace to="/auth" />} />
                  <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
                <ToastContainer />
              </Suspense>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </Grid>
  );
}

export default Sentry.withErrorBoundary(App, {
  fallback: <ErrorPage />,
});
