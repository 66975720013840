import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: true,
  page: "",
  parentPage: "",
  academicSession: {},
  asCheck: false,
};

const compsSlice = createSlice({
  name: "comps",
  initialState,
  reducers: {
    ChangeSideBar: (state) => {
      state.sidebar = !state.sidebar;
    },
    ChangeCurrentPage: (state, action) => {
      state.page = action.payload;
    },
    ChangeParentPage: (state, action) => {
      state.parentPage = action.payload;
    },
    ChangeAcademicSession: (state, action) => {
      state.academicSession = action.payload;
    },
    ChangeASCheck: (state, action) => {
      state.asCheck = action.payload;
    },
    ResetComp: (state) => {
      state.sidebar = initialState.sidebar;
      state.page = initialState.page;
      state.parentPage = initialState.parentPage;
      state.academicSession = initialState.academicSession;
      state.asCheck = initialState.asCheck;
    },
  },
});

export default compsSlice.reducer;
export const {
  ChangeSideBar,
  ChangeCurrentPage,
  ChangeParentPage,
  ChangeAcademicSession,
  ChangeASCheck,
  ResetComp,
} = compsSlice.actions;
